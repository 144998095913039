<template>
	<div class="week-picker__wrapper">
		<h4 class="picker-title">выбрать другую неделю</h4>
		<div class="week-picker">
			<button class="btn-prev" @click="changeActiveWeek('1')" :disabled="activeWeek === '1'">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					xml:space="preserve"
					width="300mm"
					height="300mm"
					version="1.1"
					style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
					viewBox="0 0 30000 30000"
					xmlns:xlink="http://www.w3.org/1999/xlink"
				>
					<g>
						<path
							d="M15000 27950c-7152,0 -12950,-5798 -12950,-12950 0,-7152 5798,-12950 12950,-12950 7152,0 12950,5798 12950,12950 0,7152 -5798,12950 -12950,12950zm15000 -12950c0,-8284 -6716,-15000 -15000,-15000 -8284,0 -15000,6716 -15000,15000 0,8284 6716,15000 15000,15000 8284,0 15000,-6716 15000,-15000zm-19667 -814l5689 -5689c1014,-1015 2627,597 1612,1612l-4859 4859 4859 4860c1015,1014 -598,2626 -1612,1612l-5689 -5689c-215,-215 -319,-500 -314,-783 -5,-283 99,-568 314,-782z"
						/>
					</g>
				</svg>
			</button>
			<svg
				class="calendar"
				xmlns="http://www.w3.org/2000/svg"
				xml:space="preserve"
				width="300mm"
				height="300mm"
				version="1.1"
				style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
				viewBox="0 0 30000 30000"
				xmlns:xlink="http://www.w3.org/1999/xlink"
			>
				<g>
					<path
						d="M22940 2520l0 -1758 -1758 0 0 1758 -12364 0 0 -1758 -1758 0 0 1758 -7060 0 0 26718 30000 0 0 -26718 -7060 0zm-15880 1757l0 1758 1758 0 0 -1758 12364 0 0 1758 1758 0 0 -1758 5302 0 0 3516 -26484 0 0 -3516 5302 0zm-5302 23203l0 -17929 26484 0 0 17929 -26484 0zm9777 -14003l5798 0 -5204 11352 1598 732 5617 -12253 0 -1589 -7809 0 0 1758zm-8019 10488l4453 0 0 1758 -4453 0 0 -1758zm21211 0l1757 0 0 1758 -1757 0 0 -1758zm-3516 0l1758 0 0 1758 -1758 0 0 -1758z"
					/>
				</g>
			</svg>

			<div class="week">
				{{ computedWeek }}
			</div>

			<button class="btn-next" @click="changeActiveWeek('2')" :disabled="activeWeek === '2'">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					xml:space="preserve"
					width="300mm"
					height="300mm"
					version="1.1"
					style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
					viewBox="0 0 30000 30000"
					xmlns:xlink="http://www.w3.org/1999/xlink"
				>
					<g>
						<path
							d="M15000 27950c-7152,0 -12950,-5798 -12950,-12950 0,-7152 5798,-12950 12950,-12950 7152,0 12950,5798 12950,12950 0,7152 -5798,12950 -12950,12950zm15000 -12950c0,-8284 -6716,-15000 -15000,-15000 -8284,0 -15000,6716 -15000,15000 0,8284 6716,15000 15000,15000 8284,0 15000,-6716 15000,-15000zm-19667 -814l5689 -5689c1014,-1015 2627,597 1612,1612l-4859 4859 4859 4860c1015,1014 -598,2626 -1612,1612l-5689 -5689c-215,-215 -319,-500 -314,-783 -5,-283 99,-568 314,-782z"
						/>
					</g>
				</svg>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		selectedDay: {
			type: String,
			required: true,
		},
		activeWeek: {
			type: String,
			default: '1',
		},
		firstMonday: {
			type: String,
			required: true,
		},
		firstSunday: {
			type: String,
			required: true,
		},
		secondMonday: {
			type: String,
			required: true,
		},
		secondSunday: {
			type: String,
			required: true,
		},
	},
	computed: {
		computedWeek() {
			return this.activeWeek === '1'
				? `${this.firstMonday} - ${this.firstSunday}`
				: `${this.secondMonday} - ${this.secondSunday}`;
		},
	},
	methods: {
		changeActiveWeek(value) {
			const selectedDay = value === '1' ? `${new Date(Date.now()).getDay()}` : '1';
			const activeWeek = value;
			this.$emit('changeActiveWeek', { selectedDay, activeWeek });
		},
	},
};
</script>
